import {
  getDetail,
  financeEditAndDetail,
  getSellerInfo,
  saveFinance
} from "../api";

export default {
  data() {
    return {
      pageLoadFlag: false,
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/finaceClaim",
          name: "finaceClaim",
          title: "资金认领"
        },
        {
          path: "/finaceClaim",
          name: "finaceClaim",
          title: "资金认领"
        }
      ],
      columns: [
        {
          title: "办事处",
          dataIndex: "hifi_agency",
          key: "hifi_agency",
          width: "220px",
          scopedSlots: { customRender: "hifi_agency" }
        },
        {
          title: "物料组",
          dataIndex: "hifi_e_product",
          key: "hifi_e_product",
          width: "150px",
          scopedSlots: { customRender: "hifi_e_product" }
        },
        {
          title: "信贷范围",
          dataIndex: "hifi_debeitrange",
          key: "hifi_debeitrange",
          width: "150px",
          scopedSlots: { customRender: "hifi_debeitrange" }
        },
        {
          title: "认领金额（元）",
          dataIndex: "e_receivableamt",
          key: "e_receivableamt",
          width: "150px",
          scopedSlots: { customRender: "e_receivableamt" }
        },
        {
          title: "业务员",
          dataIndex: "e_saleman",
          key: "e_saleman",
          width: "150px",
          scopedSlots: { customRender: "e_saleman" }
        }
      ],
      baseData: {
        baseData: [],
        financeData: [],
        maktlData: [],
        orgData: [],
        translateData: []
      },
      btnLoading:false,
      data: [],
      detail: {},
      attachs: [],
      selRows: [], //选总行scroll
      selectedRowKeys: [],
      orgCode:'',
      e_remark: "" //摘要
    };
  },
  created() {
    this.getData();
  },
  computed: {
    total() {
      // return this.data.reduce((sum, item) => {
      //   return sum + (item.e_receivableamt || 0);
      // }, 0);
      return this.data.reduce((sum, item) => {
        return  this.accAdd(sum,item.e_receivableamt)
      }, 0);
    }
  },
  methods: {
    // 解决计算失去精度的方法
    accAdd(arg1, arg2) {
      var r1, r2, m;
      try {
        r1 = arg1.toString().split(".")[1].length
      } catch (e) {
        r1 = 0
      }
      try {
        r2 = arg2.toString().split(".")[1].length
      } catch (e) {
        r2 = 0
      }
      m = Math.pow(10, Math.max(r1, r2))
      return (arg1 * m + arg2 * m) / m
    },
    delRow() {
      if (this.selRows.length < 1) {
        this.$message.warning("请先选择至少一条数据！");
        return;
      }
      for (let i = this.data.length - 1; i >= 0; i--) {
        if (this.selRows.filter(it => it.uid == this.data[i].uid).length > 0) {
          this.data.splice(i, 1);
        }
      }
      this.selectedRowKeys = [];
      this.selRows = [];
    },
    copyRow() {
      if (this.selRows.length < 1) {
        this.$message.warning("请先选择至少一条数据！");
        return;
      }
      this.selRows.forEach(item => {
        const newItem = JSON.parse(JSON.stringify(item));
        newItem.uid = this.genID(8);
        this.data.push(newItem);
      });
    },
    submit() {
      if (!this.e_remark) {
        this.$message.warning("请填写摘要！");
        return;
      }
      if (this.data.length < 1) {
        this.$message.warning("请添加认领明细！");
        return;
      }
      for (let i = 0; i < this.data.length; i++) {
        const item = this.data[i];
        if (!item.hifi_agency) {
          this.$message.warning(`请选择第${i + 1}行办事处！`);
          return;
        }
        if (!item.hifi_e_product) {
          this.$message.warning(`请选择第${i + 1}行物料组！`);
          return;
        }
        if (!item.hifi_debeitrange) {
          this.$message.warning(`请选择第${i + 1}行信贷范围！`);
          return;
        }
        if (!item.e_receivableamt || item.e_receivableamt == 0) {
          this.$message.warning(`第${i + 1}行认领金额必须大于0！`);
          return;
        }
      }
      if (this.total > this.detail.unclaimamount) {
        this.$message.warning("认领总金额不能大于未认领总金额！");
        return;
      }
      const bizdate = this.detail.bizdate.slice(0, 10);
      const entrys = this.data.map(item => {
        return {
          e_settleorg: item.e_settleorg,
          hifi_agency: item.hifi_agency,
          hifi_e_product: item.hifi_e_product,
          hifi_debeitrange: item.hifi_debeitrange,
          e_receivableamt: item.e_receivableamt,
          e_saleman: item.e_saleman,
          e_remark: item.e_remark
        };
      });
      const data = {
        method: "genClaimBill",
        params: {
          header: {
            noticebillno: this.detail.billno,
            bizdate: bizdate,
            hifi_registercom: this.detail.saleorg.number,
            e_remark: this.e_remark
          },
          entrys: entrys,
          attachs: this.attachs
        }
      };
      this.btnLoading = true
      saveFinance(data).then(res => {
        if (res.data.success) {
          this.$message.success("提交成功！");
          this.$router.push({
            path: "/finaceClaim/detail",
            query: {
              id: this.$route.query.id,
              orgCode: this.orgCode
            }
          });
        } else {
          this.$message.warning(res.data.message);
        }
        this.btnLoading = false
      }).catch(()=>{
        this.btnLoading = false
      });
    },
    getSellerInfo(row) {
      // debugger
      row.e_saleman = "";
      if (!(row.hifi_e_product && row.hifi_agency)) {
        row.salemanList = [];
        this.$forceUpdate();
        return;
      }
      const data = {
        matklCode: "" + row.hifi_e_product,
        orgCode: "" + row.hifi_agency
      };
      getSellerInfo(data).then(res => {
        row.salemanList = res.data;
        this.$forceUpdate();
      });
    },
    customRequest(options) {
      setTimeout(() => {
        options.onSuccess("success", options.file);
      }, 50);
    },
    fileChange({ fileList }) {
      this.attachs = [];
      for (let i = 0; i < fileList.length; i++) {
        let reader = new FileReader();
        reader.readAsDataURL(fileList[i].originFileObj);
        reader.onloadend = e => {
          // 读取到的图片base64 数据编码 将此编码字符串传给后台即可
          let imgData = e.target.result;
          this.attachs.push({ name: fileList[i].name, content: imgData });
        };
      }
    },
    getData() {
      const data = {
        method: "getNoticeBill",
        params: { id: this.$route.query.id },
        loginType: "CS"
      };
      getDetail(data).then(res => {
        const item = res.data.data;
        item.hifi_flowstatusName =
          item.hifi_flowstatus == "account"
            ? "银行流水"
            : item.hifi_flowstatus == "draftbill"
            ? "票据流水"
            : item.hifi_flowstatus;
        const codeToName = {
          "0": "待认领",
          "1": "部分认领",
          "2": "已认领",
          "3": "已确认",
          "4": "申诉中",
          "5": "变更中"
        };
        item.claimstatusNmae = codeToName[item.claimstatus];
        this.detail = res.data.data;
        this.e_remark =
          this.detail.oppunit == null
            ? ""
            : "收" + this.detail.oppunit + "货款";
        this.orgCode = item.saleorg.number
        if(item.unclaimamount<= 0){
          this.$router.push({
            path: "/finaceClaim/detail",
            query: {
              id: this.$route.query.id,
              orgCode: this.orgCode
            }
          });
        }
        this.getBaseData();
      });
    },
    getBaseData() {
      const params = {
        orgCode: this.orgCode,
        method: "getNoticeBill",
        params: { id: this.$route.query.id }
      };
      financeEditAndDetail(params).then(res => {
        this.baseData.baseData = JSON.parse(res.data.baseData);
        this.baseData.financeData = JSON.parse(res.data.financeData);
        this.baseData.maktlData = JSON.parse(res.data.maktlData);
        this.baseData.orgData = JSON.parse(res.data.orgData);
        this.baseData.translateData = JSON.parse(res.data.translateData);
      });
    },
    //行选中，取消
    onChange(selKeys, selRows) {
      this.selRows = selRows;
      this.selectedRowKeys = selKeys;
    },
    addRow() {
      this.data.push({
        e_settleorg: "",
        e_remark: "",
        hifi_agency: "",
        hifi_e_product: "",
        hifi_debeitrange: "",
        e_receivableamt: "",
        e_saleman: "",
        salemanList: [],
        uid: this.genID(8)
      });
    },
    //生成不重复id/后端返回的列表没有唯一键
    genID(length) {
      return Number(
        Math.random()
          .toString()
          .substr(3, length) + Date.now()
      ).toString(36);
    },
    goBack() {
      this.$router.go(-1);
    }
  }
};
